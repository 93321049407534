import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useStaticQuery, graphql } from 'gatsby';

import styles from './hero.module.sass';
import FluidImage from '../../common/image/fluid';

const Hero = ({ children, imageClassName }) => {
  const { file: heroImage } = useStaticQuery(graphql`
    query HeroQuery {
      file(absolutePath: { glob: "**/src/images/hero.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <section>
      <div className={cx('container', styles.container)}>
        <div className={cx(styles.content)}>
          {React.Children.map(children, (child) => {
            if (child.type === 'h1') {
              return React.cloneElement(child, {
                className: cx(child.props.className, styles.heading),
              });
            }

            if (child.type === 'p') {
              return React.cloneElement(child, {
                className: cx(child.props.className, styles.paragraph),
              });
            }

            return child;
          })}
        </div>
        <div className={cx(styles.image, imageClassName)}>
          <FluidImage
            image={heroImage}
            role="presentation"
            alt="Kabbage Servicing"
          />
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  imageClassName: PropTypes.string,
};

Hero.defaultProps = {
  children: null,
  imageClassName: '',
};

export default Hero;
