import React from 'react';
import { Link } from 'gatsby';

import constants from '../utils/constants';
import Layout from '../components/layout';
import Faq from '../components/blocks/faq';
import Products from '../components/blocks/products';
import Hero from '../components/blocks/hero';
import Banner from '../components/blocks/banner';
import Footer from '../components/common/footer';
import SEO from '../components/common/seo';
import styles from './homepage.module.sass';

const Home = () => {
  return (
    <Layout footer={false}>
      <SEO
        title="Home"
        description={
          'In connection with American Express’s acquisition of Kabbage, we’ve' +
          'established KServicing to maintain consistency in the way you manage' +
          'your existing loans. Any Kabbage Funding loans or PPP loans taken' +
          'prior to October 16, 2020, will continue to be serviced by KServicing.'
        }
        urlPath="/"
      />
      <Banner background="grey" containerClassName={styles.topBanner}>
        <h2 className={styles.topBannerHeading}>
          {/* <p>Your loan servicing has been transferred. If you have any questions about these changes please contact us at</p> */}
          {/* <p>855-747-5135 or contact your new loan servicer.</p> */}
        {/* <p>Effective April 17, 2023, your loan servicing is being transferred. This means that after this date, your new servicer will be collecting your PPP loan payments and processing your forgiveness applications.</p> 
        <p>You will receive two emails regarding the transfer, one from KServicing and one from your new loan servicer with additional information regarding your servicing transfer.</p> 
        <p>If you have any questions about these changes please contact us at 855-747-5135 or your new loan servicer.</p> */}
        </h2>
        {/* <Link to="https://omniagentsolutions.com/kservicing" className={styles.topBannerLink}>
          Click here for more info &gt;
        </Link> */}
      </Banner>
      <Hero>
        <h1>
          Welcome to
          <br />KServicing
        </h1>
        <p>
        Effective August 23, 2023, KServicing is no longer servicing your loan. Your loan has been transitioned to a new servicer.
        </p>
        <p>
        Please see our “Contact Us” page for additional information regarding your loan servicing.
        </p>
        {/* <a
          className="pill-button"
          href={constants.appLogin}
          target="_blank"
          rel="noreferrer"
        >
          Sign in
        </a> */}
      </Hero>
      <Banner className={styles.banner}>
        {/* <p className={styles.bannerText}>
          In connection with American Express’s acquisition of Kabbage, we’ve
          established KServicing to maintain consistency in the way you manage
          your existing loans. Any Kabbage Funding Loans or PPP loans taken 
          prior to October 16, 2020, will continue to be serviced by KServicing.
        </p> */}
      </Banner>
      <Products>
        <li className={styles.product}>
          {/* <h2 className="h3">Kabbage Funding loans</h2>
          <p>
            The fees and repayment terms of your outstanding loans taken before
            October 16, 2020, won’t change. You’ll just manage your account and
            repayments through the new KServicing site.
          </p> */}
          {/* <a
            className="cta-link"
            href={constants.appLogin}
            target="_blank"
            rel="noreferrer"
          >
            Sign in
          </a> */}
        </li>
        {/* <li className={styles.product}>
          <h2 className="h3">PPP loans</h2>
          <p>
            If you took a PPP loan through Kabbage, you can apply for loan
            forgiveness and manage repayment through the new KServicing site.
          </p>
          <a
            className="cta-link"
            href={constants.appLogin}
            target="_blank"
            rel="noreferrer"
          >
            Sign in
          </a>
        </li> */}
      </Products>
      {/* <Faq className={styles.faq} /> */}
      <Footer>
        {/* <li>
        Kabbage is a trademark of American Express used under license.
        Kabbage, Inc., doing business as KServicing, is not affiliated with American Express.
        </li> */}
        {/* <li>
          Paycheck Protection Program (PPP) Loans are provided by Kabbage, Inc.
          Kabbage, Inc. is not affiliated with American Express. 
	  Your loan through Kabbage, Inc. was made by the lender identified on your loan agreement.
        </li> */}
      </Footer>
    </Layout>
  );
};

export default Home;
